import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { GenerateItemLocals, UpdateLanguageStrings } from '@editor/shared/states/language/language.actions';
import { LanguageOtherName } from '@editor/shared/states/language/language.models';
import { BillingApi } from '@home/shared/services/billing-api.service';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { Commands } from '@shared/enums/commands.enum';
import { Logic } from '@shared/enums/logic.enum';
import { defaultOutcomeOptions, OutcomeOptions, Outcomes } from '@shared/enums/outcomes.enum';
import { Questions } from '@shared/enums/questions.enum';
import { Rights } from '@shared/enums/rights.enum';
import { UserData } from '@shared/models/account.model';
import { LanguageData2, LocalesData, TranslationData } from '@shared/models/locale.model';
import { AppState } from '@shared/models/state.model';
import { Status } from '@shared/models/status.model';
import {
  BuilderData,
  BuilderType,
  ChoiceItemData,
  DesignData,
  OutcomeData,
  QuestionActionType,
  QuestionData,
  ReleaseData,
  SettingType,
  SharingData,
  SurveyAnonymity,
  SurveyData,
  SurveyHistoryModel,
  SurveyModel,
  SurveyScoring,
  SurveySetting,
  TriggerAction,
  TriggerData,
  TriggerType,
  WithLogic,
} from '@shared/models/survey.model';
import { TriggerFeedbackSurvey } from '@shared/modules/feedback/feedback.actions';
import { FeedbackSurvey } from '@shared/modules/feedback/feedback.models';
import { shareRef } from '@shared/operators/share-ref.operator';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { EmailsManager } from '@shared/services/emails-manager.service';
import { LocalesManager } from '@shared/services/locales-manager.service';
import { OutcomesManager } from '@shared/services/outcomes-manager.service';
import { QuestionsManager } from '@shared/services/questions-manager.service';
import { SourceTypeService } from '@shared/services/source-type.service';
import { SurveysManager } from '@shared/services/surveys-manager.service';
import { BlueprintsManager } from '@shared/services/templates-manager.service';
import { TriggersManager } from '@shared/services/triggers-manager.service';
import { SwitchTeam } from '@shared/states/account.actions';
import { AccountState } from '@shared/states/account.state';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { ChangeObject, FocusActiveTitle, ShowArchiveWarning, ViewType } from '@shared/states/editor.actions';
import { LocalesState } from '@shared/states/locales/locales.state';
import { PrefsState } from '@shared/states/prefs.state';
import { RouterState } from '@shared/states/router.state';
import {
  AddGroup,
  AddOutcome,
  AddQuestion,
  AddQuestionChoice,
  AddQuestionChoices,
  AddSampleGroup,
  AddTrigger,
  ArchiveOutcome,
  ArchiveQuestion,
  ArchiveTrigger,
  ChangeOutcomeOptionsCount,
  ChangeOutcomeOptionsHideZeroScoreOutcomes,
  ChangeOutcomeOptionsScore,
  CopyQuestionSettings,
  CreateSurvey,
  DeleteOutcomeSharingImage,
  DeleteQuestion,
  DeleteQuestionChoice,
  DeleteResult,
  DeleteTrigger,
  DiscardDraft,
  DuplicateOutcome,
  DuplicateQuestion,
  GetDesign,
  GetInvites,
  GetLocales,
  GetOutcomes,
  GetOwner,
  GetQuestions,
  GetRelease,
  GetRespondents,
  GetRights,
  GetScoring,
  GetSharing,
  GetSurvey,
  GetSurveyHistory,
  GetSurveyOwnerHistory,
  GetSurveyQuestionHistory,
  GetSurveys,
  GetTriggers,
  GetUsage,
  MigrateDefaultLocales,
  MoveOutcome,
  MoveQuestion,
  MoveQuestionChoice,
  NoticeCopyOptions,
  NoticeRemove,
  PublishSurvey,
  QuestionActionDialog,
  QuestionScoredProcess,
  RemoveArchivedLogic,
  ReplaceQuestion,
  ResetOutcomeOptions,
  RestoreGroup,
  RestoreQuestion,
  RestoreResult,
  RestoreTrigger,
  SaveSurveyShareLinkTemplate,
  ScoreOutcome,
  SetAnonymous,
  SwitchQuestionChoice,
  TogglePolicy,
  ToggleQuestionOther,
  ToggleResultType,
  ToggleWelcome,
  UpdateDefaultReportSettingsData,
  UpdateDesign,
  UpdateGroup,
  UpdateOutcome,
  UpdateQuestion,
  UpdateQuestionChoiceContent,
  UpdateQuestionChoiceData,
  UpdateSettings,
  UpdateSurveyClosing,
  UpdateSurveyData,
  UpdateSurveyOnline,
  UpdateTrigger,
} from '@shared/states/survey.actions';
import { getOutcomeScoringProgress, getScoringProgress } from '@shared/states/survey.functions';
import { assertArray, isArrayShallowEqual } from '@shared/utilities/array.utilities';
import {
  addLocaleEntry,
  buildSurveyLocales,
  entryKey,
  QuestionTranslationMap,
  setSurveyTranslations,
  translatorEntriesToUpdate,
} from '@shared/utilities/language.utilities';
import { excludeByKeyList, isDeepEqual, isShallowEqual, pickBy, setByPath } from '@shared/utilities/object.utilities';
import { hasItemArchivedDependencies, isItemArchived } from '@shared/utilities/survey-data.utilities';
import { KeysOfType } from '@shared/utilities/typescript.utilities';
import { combineLatest, concat, EMPTY, forkJoin, from, merge, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { TemplatesState } from '@shared/states/templates.state';
import { ShareTemplates, SurveySharesModel } from '@shared/models/survey-shares.model';
import { IndexState } from './index/index.state';
import { SurveyIndex } from './index/index-state.models';

export interface SurveyStateModel {
  [surveyKey: string]: SurveyModel;
}

export const SURVEY_STATE_TOKEN = new StateToken<SurveyStateModel>('survey');

@Injectable()
@State<SurveyStateModel>({
  name: SURVEY_STATE_TOKEN,
  defaults: {},
})
export class SurveyState {
  constructor(
    private store: Store,
    private sm: SurveysManager,
    private bm: BlueprintsManager,
    private qm: QuestionsManager,
    private om: OutcomesManager,
    private lm: LocalesManager,
    private tm: TriggersManager,
    private ba: BillingApi,
    private em: EmailsManager,
    private cf: CloudFunctions,
    private st: SourceTypeService,
  ) {
    this.st.registerSourceType('survey', {
      action: GetSurvey,
      actionArgs: ['{$key}'],
      storeSelector: (key: string) => SurveyState.survey(key),
      obs: {},
    });
    this.st.registerSourceType('surveyDesign', {
      action: GetDesign,
      actionArgs: ['{$key}'],
      storeSelector: (key: string) => SurveyState.design(key),
      obs: {},
    });
  }

  static getSurvey(state: AppState, surveyKey?: string): SurveyModel {
    const params = RouterState.routeParams(state);
    return state.survey[surveyKey ? surveyKey : params.survey];
  }

  private static getSurveyHistory(state: AppState, surveyKey?: string): SurveyHistoryModel | undefined {
    const params = RouterState.routeParams(state);
    const survey = state.survey[surveyKey ? surveyKey : params.survey];
    return survey?.history;
  }

  @Selector()
  static surveyImage(surveyKey?: string, placeholder?: string) {
    placeholder ??= 'assets/images/survey-placeholder.png';

    return (state: AppState): string => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const background = survey?.design?.background;

      return background?.url || background?.thumb || background?.source || placeholder;
    };
  }

  @Selector()
  static surveyName(surveyKey?: string, placeholder?: string) {
    placeholder ??= $localize`Untitled Survey`;

    return (state: AppState): string => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.survey?.name || placeholder;
    };
  }

  static duplicateName(surveyKey: string) {
    const placeholder = $localize`Untitled Survey`;
    return createSelector([IndexState.surveys], (surveys: SurveyIndex[]) => {
      const surveyName = surveys.find((s) => s.$key === surveyKey)?.surveyName;
      return $localize`Copy of ${surveyName || placeholder}`;
    });
  }

  @Selector()
  static surveyOwner(surveyKey?: string, placeholder?: string) {
    placeholder ??= $localize`Unknown owner`;

    return (state: AppState): string => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.owner?.name || survey?.owner?.email || placeholder;
    };
  }

  @Selector()
  static surveyHistoryOwner(surveyKey?: string) {
    const placeholder = $localize`Unknown owner`;

    return (state: AppState): string => {
      const survey = SurveyState.getSurveyHistory(state, surveyKey);

      return survey?.owner?.name || placeholder;
    };
  }

  @Selector()
  static surveyPublished(surveyKey?: string) {
    return (state: AppState): boolean => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return !!survey?.survey?.published;
    };
  }

  @Selector()
  static surveyUsage(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      return survey.usage;
    };
  }

  @Selector()
  static isDraft(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const data = survey && survey.survey;

      return !!data && (data.modified || 1) > (data.published || 0);
    };
  }

  @Selector()
  static lastPublish(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.survey?.published || 0;
    };
  }

  @Selector()
  static lastModified(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const data = survey?.survey;

      return data?.modified || data?.created || 0;
    };
  }

  @Selector()
  static isPublished(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return !!(survey?.survey?.published && survey.release?.online);
    };
  }

  @Selector()
  static hasClosingTime(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return !!survey?.release?.closeAt;
    };
  }

  @Selector()
  static anonymous(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.survey?.anonymous;
    };
  }

  @Selector()
  static surveyStatus(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const isPublished = !!(survey?.survey?.published && survey?.release?.online);
      const lastPublished = survey?.survey?.published || 0;

      return isPublished ? Status.Online : lastPublished ? Status.Offline : Status.Warning;
    };
  }

  @Selector()
  static lockedSurveysDetailsLoaded() {
    return (state: AppState): boolean => {
      return state.billing.lockedSurveys
        .map((status) => status.surveyKey)
        .filter(Boolean)
        .map((key) => state.survey[key])
        .every((survey) => {
          return survey && 'survey' in survey && 'design' in survey && 'owner' in survey && 'usage' in survey;
        });
    };
  }

  @Selector()
  static activeSurvey(surveyKey?: string) {
    return (state: AppState): SurveyModel => SurveyState.getSurvey(state, surveyKey);
  }

  @Selector()
  static survey(surveyKey?: string) {
    return (state: AppState): SurveyData => {
      return SurveyState.getSurvey(state, surveyKey)?.survey;
    };
  }

  @Selector()
  static settings(surveyKey?: string) {
    return (state: AppState): SurveySetting[] => {
      return SurveyState.getSurvey(state, surveyKey)?.survey?.settings || [];
    };
  }

  @Selector()
  static shareTemplates(surveyKey?: string) {
    return (state: AppState): Partial<ShareTemplates> => {
      return SurveyState.getSurvey(state, surveyKey)?.survey?.shareTemplates || {};
    };
  }

  @Selector()
  static surveyHistory(surveyKey?: string) {
    return (state: AppState): SurveyData | undefined => {
      return SurveyState.getSurveyHistory(state, surveyKey)?.survey;
    };
  }

  @Selector()
  static questions(surveyKey?: string) {
    return (state: AppState): QuestionData[] => {
      return SurveyState.getSurvey(state, surveyKey)?.questions || [];
    };
  }

  @Selector()
  static questionsHistory(surveyKey?: string) {
    return (state: AppState): QuestionData[] => {
      return SurveyState.getSurveyHistory(state, surveyKey)?.questions || [];
    };
  }

  @Selector()
  static hasArchivedQuestions(surveyKey?: string) {
    return (state: AppState) => {
      const questions = SurveyState.getSurvey(state, surveyKey)?.questions || [];

      return questions.some((q) => q.archived);
    };
  }

  @Selector()
  static isArchived(data: { archived?: boolean }, surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return isItemArchived(data, survey?.questions);
    };
  }

  @Selector()
  static isHistoryArchived(data: { archived?: boolean }, surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurveyHistory(state, surveyKey);

      return isItemArchived(data, survey?.questions);
    };
  }

  @Selector()
  static hasArchivedDependencies(data: { archived?: boolean } & WithLogic, surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const questions = assertArray(survey?.questions);

      return SurveyState.isDependentOnArchivedQuestions(data, questions);
    };
  }

  private static isDependentOnArchivedQuestions(data: { archived?: boolean } & WithLogic, questions: QuestionData[]) {
    return hasItemArchivedDependencies(data, questions);
  }

  @Selector()
  static hasLogicDependencies(data: BuilderData) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state);
      const questions = assertArray(survey?.questions);
      const outcomes = assertArray(survey?.outcomes);
      const triggers = assertArray(survey?.triggers);

      return [...questions, ...outcomes, ...triggers]
        .filter((q) => !q.archived)
        .some((q) => {
          return Object.keys(q.showIf || {}).some((key) => {
            const groupKey = questions.find((q2) => q2.$key === key)?.group;

            return key === data.$key || groupKey === data.$key;
          });
        });
    };
  }

  @Selector()
  static outcomes(surveyKey?: string) {
    return (state: AppState): OutcomeData[] => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.outcomes || [];
    };
  }

  @Selector()
  static hasArchivedOutcomes(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const outcomes = assertArray(survey?.outcomes);

      return outcomes.some((o) => o.archived);
    };
  }

  @Selector()
  static hasDependentOutcomes(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const outcomes = assertArray(survey?.outcomes);
      const questions = assertArray(survey?.questions);

      return outcomes.some((o) => SurveyState.isDependentOnArchivedQuestions(o, questions));
    };
  }

  @Selector()
  static outcomeOptions(surveyKey?: string) {
    return (state: AppState): OutcomeOptions => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const outcomes = (survey && survey.outcomes.filter((outcome) => outcome.type === Outcomes.OUTCOME)) || [];
      const options = (survey && survey.survey && survey.survey.resultsOptions) || defaultOutcomeOptions;

      return options === defaultOutcomeOptions ? { ...options, count: outcomes.length } : options;
    };
  }

  @Selector()
  static isOutcomeOptionsDefault(surveyKey?: string) {
    return (state: AppState): boolean => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const options = survey && survey.survey && survey.survey.resultsOptions;

      return !options || isShallowEqual(options, defaultOutcomeOptions);
    };
  }

  @Selector()
  static hasScoredOutcomes(surveyKey?: string) {
    return (state: AppState): boolean => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return !!survey?.outcomes?.some((outcome) => !outcome.archived && getScoringProgress(survey, outcome) > 0);
    };
  }

  @Selector()
  static triggers(surveyKey?: string) {
    return (state: AppState): TriggerData[] => {
      return SurveyState.getSurvey(state, surveyKey)?.triggers || [];
    };
  }

  @Selector()
  static hasArchivedTriggers(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const triggers = survey?.triggers || [];

      return triggers.some((o) => o.archived);
    };
  }

  @Selector()
  static design(surveyKey?: string) {
    return (state: AppState): DesignData => {
      return SurveyState.getSurvey(state, surveyKey)?.design;
    };
  }

  @Selector()
  static logo(surveyKey?: string) {
    return (state: AppState): string | null => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.design?.background?.url || null;
    };
  }

  @Selector()
  static sharing(surveyKey?: string) {
    return (state: AppState): SharingData | undefined => {
      return SurveyState.getSurvey(state, surveyKey)?.sharing;
    };
  }

  @Selector()
  static release(surveyKey?: string) {
    return (state: AppState): ReleaseData | undefined => {
      return SurveyState.getSurvey(state, surveyKey)?.release;
    };
  }

  @Selector()
  static revisions(surveyKey?: string) {
    return (state: AppState): { $key: string; published: string | null }[] => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return Object.entries(survey?.release?.revisions || {}).map(([$key, published]) => ({
        $key,
        published,
      }));
    };
  }

  @Selector()
  static rights(surveyKey?: string) {
    return (state: AppState): Rights | undefined => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey && survey.rights;
    };
  }

  @Selector()
  static scoring(surveyKey?: string) {
    return (state: AppState): SurveyScoring | undefined => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey && survey.scoring;
    };
  }

  @Selector()
  static locales(surveyKey?: string) {
    return (state: AppState): LocalesData | undefined => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey && survey.locales;
    };
  }

  @Selector()
  static respondents(surveyKey?: string) {
    return (state: AppState): number | undefined => {
      return SurveyState.getSurvey(state, surveyKey)?.respondents;
    };
  }

  @Selector()
  static anonymity(surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const surveyData: SurveyData = survey?.survey;

      const anonymous = surveyData?.anonymous || 0;
      const anonymityMigrated = surveyData?.anonymityMigrated || false;
      const isAnonymous = anonymous > 0;
      const hasRespondents = survey?.respondents > 0;

      const anonymity: SurveyAnonymity = {
        anonymityMigrated,
        anonymous,
        isAnonymous,
        hasRespondents,
      };

      return anonymity;
    };
  }

  @Selector()
  static questionRespondents(questionKey: string, surveyKey?: string) {
    return (state: AppState): number => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const question = survey.questions.find((q) => q.$key === questionKey);

      return question?.$repondents || 0;
    };
  }

  @Selector()
  static templateKey(surveyKey?: string) {
    return (state: AppState): string => {
      const survey = SurveyState.getSurvey(state, surveyKey);

      return survey?.survey.template;
    };
  }

  @Selector([RouterState.routeParams])
  static activeSurveyKey(_, params): string | undefined {
    return params.survey;
  }

  static isOwner(surveyKey?: string) {
    return createSelector([SurveyState.rights(surveyKey)], (rights) => Rights.hasRights(Rights.OWNER, rights));
  }

  static canEdit(surveyKey?: string) {
    return createSelector(
      [AccountState.userRole, SurveyState.rights(surveyKey)],
      (role, rights) => Rights.hasRights(Rights.ADMIN, role) || Rights.hasRights(Rights.EDIT, rights),
    );
  }

  static canView(surveyKey?: string) {
    return createSelector(
      [AccountState.userRole, SurveyState.rights(surveyKey)],
      (role, rights) => Rights.hasRights(Rights.ADMIN, role) || Rights.hasRights(Rights.VIEW, rights),
    );
  }

  static hasSurveyRight(surveyKey: string | string[], rightNeeded: Rights, userKey?: string) {
    return createSelector(
      [AccountState.user, AccountState.teamKey, AccountState.userRole, AccountState.teamUsers],
      (userData: UserData, teamKey: string, userRole: Rights, users: Record<string, Rights>) => {
        return assertArray(surveyKey).every((key) => {
          const right = userKey ? users[userKey] : userData?.surveys?.[teamKey]?.[key];

          return (
            Rights.hasRights(rightNeeded, right || Rights.NONE) ||
            Rights.hasRights(Rights.ADMIN, userKey ? right : userRole)
          );
        });
      },
    );
  }

  @Selector()
  static logicableQuestions(surveyKey?: string) {
    return (state: AppState): QuestionData[] => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      return !survey ? [] : survey.questions.filter(Logic.logicable);
    };
  }

  @Selector()
  static groupItems(surveyKey?: string) {
    return (state: AppState): QuestionData[] => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      return !survey ? [] : survey.questions.filter(Questions.group);
    };
  }

  @Selector()
  static scoringProgress(outcomeKey: string) {
    return (state: AppState): [number, number] => {
      if (outcomeKey) {
        const survey = SurveyState.getSurvey(state);
        const outcome = survey?.outcomes?.find((o) => o.$key === outcomeKey);

        return getOutcomeScoringProgress(survey, outcome);
      }

      return [0, 0];
    };
  }

  @Selector()
  static surveys(state: SurveyStateModel): SurveyModel[] {
    return Object.values(state || {})
      .filter((model) => Object.keys(model.survey?.users || {}).length > 0)
      .sort(SurveyState.sortByModified);
  }

  static sortByModified(a: SurveyModel, b: SurveyModel) {
    if (!a.survey || !b.survey) {
      return 0;
    } else {
      let A = a.survey.modified;
      let B = b.survey.modified;

      A = A === -1 || !A ? a.survey.published || a.survey.created : A;
      B = B === -1 || !B ? b.survey.published || b.survey.created : B;

      if (A > B) {
        return -1;
      } else if (A !== null && A < B) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  static model(surveyKey?: string) {
    return createSelector([SurveyState], (state: SurveyStateModel): SurveyModel => state[surveyKey]);
  }

  @Selector()
  static defaultLanguage(surveyKey?: string) {
    return (state: AppState): string => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      return survey?.survey.language;
    };
  }

  @Selector()
  static questionChoices(questionKey: string, surveyKey?: string) {
    return (state: AppState): ChoiceItemData[] => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const question = survey.questions.find((q) => q.$key === questionKey);

      return question?.choiceList;
    };
  }

  @Selector()
  static choiceHasTranslation(questionKey: string, choiceKey: string, surveyKey?: string) {
    return (state: AppState) => {
      const survey = SurveyState.getSurvey(state, surveyKey);
      const translationData = survey.locales?.strings || {};

      return Object.values(translationData).some((data: TranslationData) =>
        Boolean(data[`choice-${questionKey}-${choiceKey}`]),
      );
    };
  }

  @Selector([TemplatesState.templates, SurveyState.activeSurveyKey])
  static isTemplateModel(_, templates: SurveyModel[], surveyKey: string) {
    return templates.some((t) => t.template.uuid === surveyKey);
  }

  @Selector([TemplatesState.templates, SurveyState.activeSurvey(), SurveyState.isTemplateModel])
  static isTemplateInstance(_, templates: SurveyModel[], surveyModel: SurveyModel, isTemplateModel: boolean) {
    const templateKey = surveyModel?.survey?.template;
    const hasSettings = surveyModel?.survey?.settings?.length > 0;

    return Boolean(!isTemplateModel && templateKey && hasSettings);
  }

  /**
   * Dispatch these actions to load data for surveys
   *
   * @param surveyKeys
   */
  static surveyListActions(surveyKeys: string[]) {
    return surveyKeys.reduce(
      (acc, key) => [...acc, new GetDesign(key), new GetSurvey(key), new GetOwner(key), new GetRelease(key)],
      [],
    );
  }

  @StreamAction(GetSurveyHistory)
  getSurveyHistory(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetSurvey): Observable<void> {
    return this.getSurveyHistoryItem(ctx, surveyKey, 'survey');
  }

  @StreamAction(GetSurveyOwnerHistory)
  getSurveyOwnerHistory(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetSurvey): Observable<void> {
    return this.getSurveyHistoryItem(ctx, surveyKey, 'owner');
  }

  @StreamAction(GetSurveyQuestionHistory)
  getSurveyQuestionHistory(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetSurvey): Observable<void> {
    return this.getSurveyHistoryItem(ctx, surveyKey, 'questions');
  }

  @StreamAction(GetSurvey)
  getSurvey(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetSurvey): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'survey');
  }

  @StreamAction(GetDesign)
  getDesign(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetDesign): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'design');
  }

  @StreamAction(GetLocales)
  getLocales(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetLocales): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'locales');
  }

  @StreamAction(GetSharing)
  getSharing(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetSharing): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'sharing');
  }

  @StreamAction(GetScoring)
  getScoring(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetScoring): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'scoring');
  }

  @StreamAction(GetRelease)
  getRelease(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetRelease): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'release');
  }

  @StreamAction(GetOutcomes)
  getOutcomes(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetOutcomes): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'outcomes');
  }

  @StreamAction(GetTriggers)
  getTriggers(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetTriggers): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'triggers');
  }

  @StreamAction(GetQuestions)
  getQuestions(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetQuestions): Observable<void> {
    const transform = (questions: QuestionData[]) =>
      [...(questions || [])]
        .map((question) => {
          if (question.choiceList) {
            const inserting =
              [...this.store.selectSnapshot(SurveyState.questions())]
                .find(({ $key }) => $key === question.$key)
                ?.choiceList?.filter(
                  (choice) =>
                    !!choice.$insertKey && question.choiceList.every(({ $key }) => $key !== choice.$insertKey),
                ) || [];

            if (inserting.length) {
              question = { ...question, choiceList: [...question.choiceList] };
              question.choiceList.push(...inserting);
            }
          }

          return question;
        })
        .sort((a, b) => a.order - b.order);

    return this.getSurveyItem(ctx, surveyKey, 'questions', transform);
  }

  @StreamAction(GetUsage)
  getUsage(
    { getState, patchState }: StateContext<SurveyStateModel>,
    { surveyKey }: GetUsage,
  ): Observable<SurveyStateModel> {
    return this.ba
      .getSurveyUsage(surveyKey)
      .pipe(map((data) => patchState({ [surveyKey]: { ...getState()[surveyKey], usage: data } })));
  }

  @StreamAction(GetOwner)
  getOwner(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetOwner): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'owner');
  }

  @StreamAction(GetRights)
  getRights(ctx: StateContext<SurveyStateModel>, { surveyKey }: GetRights): Observable<void> {
    return this.getSurveyItem(ctx, surveyKey, 'rights');
  }

  @StreamAction(GetRespondents)
  getRespondents(
    { patchState, getState }: StateContext<SurveyStateModel>,
    { surveyKey }: GetRespondents,
  ): Observable<unknown> {
    return this.sm
      .surveyRespondents(surveyKey)
      .pipe(tap((respondents: number) => patchState({ [surveyKey]: { ...getState()[surveyKey], respondents } })));
  }

  @StreamAction(GetInvites)
  getInvites({ patchState, getState }: StateContext<SurveyStateModel>, { surveyKey }: GetInvites) {
    return this.sm.surveyInvites(surveyKey).pipe(
      tap(() => {
        patchState({ [surveyKey]: { ...getState()[surveyKey] } });
      }),
    );
  }

  @Action(CreateSurvey)
  createSurvey(_, { options }: CreateSurvey): Observable<void> {
    return this.sm.createSurvey(options);
  }

  @Action(UpdateSurveyData)
  updateSurveyData(
    { setState }: StateContext<SurveyStateModel>,
    { surveyKey, data }: UpdateSurveyData,
  ): Observable<void> {
    surveyKey ||= this.store.selectSnapshot(SurveyState.activeSurveyKey);

    try {
      setState(patch({ [surveyKey]: patch({ survey: patch(data) }) }));
    } catch {}

    return from(this.sm.updateSurvey(surveyKey, data));
  }

  @Action(UpdateDefaultReportSettingsData)
  updateDefaultReportSettingsData(
    { setState }: StateContext<SurveyStateModel>,
    { surveyKey, data }: UpdateDefaultReportSettingsData,
  ): Observable<unknown> {
    surveyKey ||= this.store.selectSnapshot(SurveyState.activeSurveyKey);

    try {
      setState(patch({ [surveyKey]: patch({ survey: patch({ defaultReportSettings: patch(data) }) }) }));
    } catch {}

    return from(this.sm.updateDefaultReportSettings(surveyKey, data));
  }

  @Action(UpdateSettings)
  updateSettings(_, { settingsList, remove }: UpdateSettings) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const { questions } = this.store.selectSnapshot(SurveyState.activeSurvey());

    const isQuestionSetting = (setting: SurveySetting) =>
      [
        SettingType.QuestionEditingDisabled,
        SettingType.QuestionDeletingDisabled,
        SettingType.QuestionSkipAnonymity,
        SettingType.QuestionExtraSensitiveAnonymity,
      ].includes(setting.type);
    const isValidSetting = (setting: SurveySetting) =>
      questions?.some(({ $key, uuid }) => uuid === setting.value || $key === setting.value);
    const hasConflictingSetting = (arr: SurveySetting[], item: SurveySetting) =>
      [SettingType.QuestionSkipAnonymity, SettingType.QuestionExtraSensitiveAnonymity].includes(item.type) &&
      arr.some(
        (s) =>
          s.value === item.value &&
          [SettingType.QuestionSkipAnonymity, SettingType.QuestionExtraSensitiveAnonymity].includes(s.type),
      );

    const currentSettings = this.store
      .selectSnapshot(SurveyState.settings())
      .filter((s) => (!isQuestionSetting(s) ? true : isValidSetting(s)));

    const settings = currentSettings
      .filter((setting) => {
        if (isQuestionSetting(setting)) {
          return !settingsList.some((s) => isShallowEqual(s, setting)) && !hasConflictingSetting(settingsList, setting);
        } else {
          return !settingsList.some((s) => s.type === setting.type);
        }
      })
      .concat(remove ? null : settingsList)
      .filter(Boolean);

    return from(this.sm.updateSettings(surveyKey, settings));
  }

  @Action(SaveSurveyShareLinkTemplate)
  saveSurveyShareLinkTemplate(
    _: StateContext<SurveySharesModel>,
    { shareLink, remove }: SaveSurveyShareLinkTemplate,
  ): Observable<unknown> {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);

    return from(this.sm.updateShareTemplate(surveyKey, shareLink, remove));
  }

  @Action(ToggleWelcome)
  toggleWelcome(
    { dispatch }: StateContext<SurveyStateModel>,
    { surveyKey, toggle, data, language }: ToggleWelcome,
  ): Observable<void> {
    let key = toggle ? '' : this.store.selectSnapshot(RouterState.queryParams).object;

    if (!key && !toggle) {
      const question = this.store.selectSnapshot(SurveyState.questions())?.[0];

      if (question) {
        key = question.$key;
      }
    }

    const defaultLanguage = this.store.selectSnapshot(SurveyState.survey())?.language;
    let update: Partial<SurveyData> = { ...data, welcome: toggle };

    const languageUpdate$ = language
      ? this.store.dispatch(new UpdateLanguageStrings(buildSurveyLocales(update as SurveyData), language, void 0, true))
      : of(void 0);

    if (toggle && defaultLanguage) {
      const translations = this.store.selectSnapshot(SurveyState.locales())?.strings?.[defaultLanguage];
      const surveyTranslations = setSurveyTranslations(update as SurveyData, translations);

      update = {
        ...update,
        ...Object.entries(surveyTranslations).reduce(
          (a, [updateKey, value]) => ({
            ...a,
            [updateKey]: value || null,
          }),
          {},
        ),
      };
    }

    return forkJoin([this.sm.updateSurvey(surveyKey, update), languageUpdate$]).pipe(
      switchMap(() =>
        dispatch(new ChangeObject(key)).pipe(
          switchMap(() => (toggle ? dispatch(new FocusActiveTitle(key)) : of(void 0))),
        ),
      ),
    );
  }

  @Action(TogglePolicy)
  togglePolicy(ctx: StateContext<SurveyStateModel>, { surveyKey, policy }: TogglePolicy): Observable<void> {
    return from(this.sm.updateSurvey(surveyKey, { policy }));
  }

  @Action(SetAnonymous)
  setAnonymous(ctx: StateContext<SurveyStateModel>, { surveyKey, anonymous }: SetAnonymous) {
    return from(this.sm.updateSurvey(surveyKey, { anonymous }));
  }

  @Action(ToggleResultType)
  toggleResultType(
    { dispatch }: StateContext<SurveyStateModel>,
    { surveyKey, type }: ToggleResultType,
  ): Observable<void> {
    const outcomes = this.store.selectSnapshot(SurveyState.outcomes());
    const outcome = outcomes.find(({ type: outcomeType }) => outcomeType === type);
    const mapOutcomeTo = outcome
      ? of(outcome.$key)
      : this.om.addOutcomes({ type } as OutcomeData).pipe(map(([key]) => key));

    return from(this.sm.updateSurvey(surveyKey, { results: type })).pipe(
      mergeMap(() => mapOutcomeTo),
      mergeMap((key) => dispatch(new ChangeObject(key, ViewType.Result, type === Outcomes.OUTCOME && void 0))),
    );
  }

  @Action(AddQuestion)
  addQuestion(
    { dispatch }: StateContext<SurveyStateModel>,
    { question, index, language }: AddQuestion,
  ): Observable<void> {
    if (index == null || index < 0) {
      index = this.store.selectSnapshot(SurveyState.questions()).length;
    }

    return this.qm.addQuestions(question as QuestionData, index).pipe(
      switchMap(([key]) =>
        dispatch(new GenerateItemLocals([{ ...question, $key: key }], 'questions', language)).pipe(mapTo(key)),
      ),
      mergeMap((key) => dispatch(new ChangeObject(key, ViewType.Question, false, { questionAdded: key }))),
    );
  }

  @Action(AddGroup)
  addGroup({ dispatch }: StateContext<SurveyStateModel>, { index }: AddGroup): Observable<void> {
    if (index == null) {
      index = (this.store.selectSnapshot(SurveyState.questions()) || []).length;
    }

    return this.qm.addGroup(index).pipe(mergeMap(([key]) => dispatch(new ChangeObject(key, ViewType.Question, false))));
  }

  @Action(DuplicateQuestion)
  duplicateQuestion({ dispatch }: StateContext<SurveyStateModel>, { question }: DuplicateQuestion): Observable<void> {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const questions = [
      question,
      ...Questions.groupQuestions(question, this.store.selectSnapshot(SurveyState.questions())),
    ];

    return this.qm.copyQuestions(questions).pipe(
      delay(1),
      switchMap(({ keys, choiceKeys }) => {
        const isTemplateModel = this.store.selectSnapshot(SurveyState.isTemplateModel);
        if (isTemplateModel) {
          questions.forEach(({ $key, uuid }, i) => {
            const updates = [];
            const settings = this.store.selectSnapshot(SurveyState.settings());
            settings.forEach((setting) => {
              if (setting.value === $key || setting.value === uuid) {
                updates.push({ type: setting.type, value: keys[i] });
              }
            });

            this.store.dispatch(new UpdateSettings(updates, false));
          });
        }
        return this.store.selectOnce(SurveyState.questions()).pipe(
          switchMap((allQuestions) =>
            dispatch(
              new GenerateItemLocals(
                allQuestions.filter(({ $key }) => keys.includes($key)).map((q, i) => ({ ...q, $key: keys[i] })),
                'questions',
              ),
            ).pipe(
              switchMap(() => this.lm.duplicateItemTranslations(surveyKey, questions, keys, choiceKeys)),
              mapTo(keys[0]),
            ),
          ),
        );
      }),
      mergeMap((key) => dispatch(new ChangeObject(key, ViewType.Question, false))),
    );
  }

  @Action(UpdateQuestion)
  updateQuestion(
    ctx: StateContext<SurveyStateModel>,
    { questionKey, data, force }: UpdateQuestion,
  ): Observable<unknown> {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const idx = questions.findIndex(({ $key }) => $key === questionKey);
    const question = questions[idx];

    if (!question) {
      return of(void 0);
    }

    let attached: BuilderData[] = [];
    let actions: any[] = [];

    if (Logic.isLogicUpdate(question, data)) {
      const logicData = this.getUpdateLogicActions(question, true);
      attached = logicData.attached;
      actions = logicData.actions;
    }

    if (!force && attached.length > 0) {
      return ctx.dispatch(
        new QuestionActionDialog({
          question,
          attached,
          action: QuestionActionType.Editing,
          confirmAction: new UpdateQuestion(question.$key, data, true),
        }),
      );
    }

    const action = actions.length ? ctx.dispatch(actions) : of(void 0);

    this.updateQuestionData(ctx, questionKey, data);

    return action.pipe(switchMap(() => this.qm.updateQuestion(question.$key, data)));
  }

  @Action(CopyQuestionSettings)
  copyQuestionSettings(
    { dispatch }: StateContext<SurveyStateModel>,
    { source, target, force }: CopyQuestionSettings,
  ): Observable<void> {
    const questions = this.store.selectSnapshot(SurveyState.questions());
    const targetQuestion = questions.find(({ $key }) => $key === target);
    const sourceQuestion = questions.find(({ $key }) => $key === source);
    const { attached, actions } = this.getUpdateLogicActions(targetQuestion, true);

    if (!force && attached.length > 0) {
      return this.store.dispatch(
        new QuestionActionDialog({
          question: targetQuestion,
          attached,
          action: QuestionActionType.Editing,
          confirmAction: new CopyQuestionSettings(source, target, true),
        }),
      );
    }

    actions.push(new NoticeCopyOptions(sourceQuestion));

    return this.qm.copyQuestionOptions([target], source, questions).pipe(mergeMap(() => dispatch(actions)));
  }

  @Action(UpdateOutcome)
  updateResult(ctx: StateContext<SurveyStateModel>, { resultKey, data }: UpdateOutcome) {
    return from(this.om.updateOutcome(resultKey, data));
  }

  @Action(DuplicateOutcome)
  duplicateOutcome({ dispatch }: StateContext<SurveyStateModel>, { outcome }: DuplicateOutcome) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);

    return this.om
      .copyOutcome(outcome)
      .pipe(
        switchMap(([key]) =>
          forkJoin([
            this.lm.duplicateItemTranslations(surveyKey, [outcome], [key]),
            this.om.copyOutcomeScore(outcome.$key, key),
            dispatch(new ChangeObject(key, ViewType.Result, false)),
          ]),
        ),
      );
  }

  @Action(UpdateGroup)
  updateGroup(ctx: StateContext<SurveyStateModel>, { groupKey, data, force, skipType }: UpdateGroup) {
    const questions = this.store.selectSnapshot(SurveyState.questions());
    const group = questions.find(({ $key }) => $key === groupKey);
    const groupQuestions = Questions.groupQuestions(group, questions);

    if (!force && !this.qm.checkGroupUpdateAndNotify(group, groupQuestions, data, skipType)) {
      return of(void 0);
    }

    if (data.type === Questions.GROUP_SCORED) {
      data = this.qm.addScoredData(group, groupQuestions);
    }

    return from(this.qm.updateQuestion(groupKey, data));
  }

  @Action(DeleteQuestion)
  deleteQuestion({ dispatch }: StateContext<SurveyStateModel>, { question, force }: DeleteQuestion): Observable<void> {
    const isGroup = Questions.group(question);
    const questions = this.store.selectSnapshot(SurveyState.questions());
    const { attached, actions } = this.getUpdateLogicActions(question);

    if (!force) {
      if (attached.length > 0 || (!!isGroup && Questions.groupQuestions(question, questions).length > 0)) {
        return this.store.dispatch(
          new QuestionActionDialog({
            question,
            attached,
            action: isGroup ? QuestionActionType.DeleteGroup : QuestionActionType.Delete,
            confirmAction: new DeleteQuestion(question, true),
          }),
        );
      }
    }

    const activeKey = this.store.selectSnapshot(RouterState.queryParams).object;

    let newKey: string = activeKey;
    let inGroup = false;

    if (isGroup) {
      const activeQuestion = questions.find(({ $key }) => activeKey === $key);

      if (activeQuestion) {
        inGroup = activeQuestion.group === question.$key;
      }
    }

    if (inGroup || activeKey === question.$key) {
      let index = questions.findIndex(({ $key }) => question.$key === $key) - 1;

      if (questions.length > 1 && index === -1) {
        index = 1;
      }

      newKey = index >= 0 ? questions[index].$key : '';
    }

    const action = isGroup ? this.qm.removeGroup(question) : this.qm.removeQuestion(question);

    return action.pipe(
      mergeMap((keys) => {
        const restoreAction = isGroup ? new RestoreGroup(keys) : new RestoreQuestion(question);

        return dispatch([
          ...actions,
          new ChangeObject(newKey),
          new NoticeRemove(isGroup ? BuilderType.Group : BuilderType.Question, restoreAction),
        ]);
      }),
    );
  }

  @Action(ReplaceQuestion)
  replaceQuestion(
    { dispatch }: StateContext<SurveyStateModel>,
    { source, replacement, forceAction }: ReplaceQuestion,
  ): Observable<unknown> {
    const questions = this.store.selectSnapshot(SurveyState.questions());
    const question = questions.find(({ $key }) => $key === source);
    const { attached, actions } = this.getUpdateLogicActions(question);

    if (forceAction !== true && attached.length > 0) {
      return this.store.dispatch(
        new QuestionActionDialog({
          question,
          attached,
          action: QuestionActionType.Editing,
          confirmAction: forceAction,
        }),
      );
    }

    return forkJoin([this.qm.replaceQuestion(source, replacement), dispatch(actions)]);
  }

  @Action(RestoreQuestion)
  restoreQuestion({ dispatch }: StateContext<SurveyStateModel>, { question }: RestoreQuestion): Observable<void> {
    return from(this.qm.restoreQuestion(question)).pipe(
      mergeMap(() => dispatch(new ChangeObject(question.$key, ViewType.Question))),
    );
  }

  @Action(MoveQuestion)
  moveQuestion(
    { dispatch, patchState, getState }: StateContext<SurveyStateModel>,
    { question, index, group, force }: MoveQuestion,
  ): Observable<any> {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];

    if (!force && !this.qm.checkScoredAndNotify(question, questions, group, index)) {
      return of(void 0);
    }

    const attached = Logic.attachedMove(question, questions, index);
    const movingUp = questions.findIndex(({ $key }) => question.$key === $key) > index;

    if (!force && attached.length > 0) {
      return this.store.dispatch(
        new QuestionActionDialog({
          question,
          attached,
          action: movingUp ? QuestionActionType.MoveUp : QuestionActionType.MoveDown,
          confirmAction: new MoveQuestion(question, index, group, true),
        }),
      );
    }

    const idx = questions.indexOf(question);

    if (idx !== -1) {
      questions[idx] = { ...question, group: group || null };
      let newIndex = index == null ? questions.length : index;

      if (newIndex > idx) {
        newIndex--;
      }

      moveItemInArray(questions, idx, newIndex);

      if (Questions.group(question)) {
        Questions.groupQuestions(question, questions).forEach((child, i) => {
          let newChildIndex = newIndex + i + 1;

          if (newIndex > idx) {
            newChildIndex--;
          }

          moveItemInArray(questions, questions.indexOf(child), newChildIndex);
        });
      }

      const survey = this.store.selectSnapshot(RouterState.routeParams).survey;

      patchState({
        [survey]: { ...getState()[survey], questions },
      });
    }

    const actions: (UpdateGroup | UpdateQuestion)[] = [];
    const keys = Questions.attachedKeys(question, questions);

    if (movingUp) {
      actions.push(
        ...keys
          .map((key) => {
            const attach = questions.find(({ $key }) => $key === key);

            if (attach) {
              const showIf = { ...(attach.showIf || {}) };
              attached.forEach((q) => delete showIf[q.$key]);
              return Questions.group(attach) ? new UpdateGroup(key, { showIf }) : new UpdateQuestion(key, { showIf });
            }
          })
          .filter((a) => !!a),
      );
    } else {
      actions.push(
        ...attached.map((attach) => {
          const showIf = { ...(attach.showIf || {}) };
          keys.forEach((key) => delete showIf[key]);

          return Questions.group(attach)
            ? new UpdateGroup(attach.$key, { showIf })
            : new UpdateQuestion(attach.$key, { showIf });
        }),
      );
    }

    const action = actions.length ? dispatch(actions) : of(void 0);

    return action.pipe(mergeMap(() => this.qm.moveQuestion(question, index, group)));
  }

  @Action(RestoreGroup)
  restoreGroup({ dispatch }: StateContext<SurveyStateModel>, { keys }: RestoreGroup): Observable<void> {
    return this.qm.restoreGroup(keys).pipe(mergeMap(() => dispatch(new ChangeObject(keys[0], ViewType.Question))));
  }

  @Action(AddSampleGroup)
  addSampleGroup({ dispatch }: StateContext<SurveyStateModel>, { questions }: AddSampleGroup): Observable<unknown> {
    return this.qm.insertQuestions(questions, true).pipe(
      switchMap((questionKeys) => {
        const insertedQuestions = questions.map((question, i) => ({
          ...question,
          $key: questionKeys[i].$key,
          group: (question.group && questionKeys[questions.findIndex((q) => q.$key === question.group)])?.$key || null,
        }));

        return dispatch(new GenerateItemLocals(insertedQuestions, 'questions')).pipe(mapTo(questionKeys[0].$key));
      }),
      switchMap((groupKey) => dispatch(new ChangeObject(groupKey, ViewType.Question, false))),
    );
  }

  @Action(AddOutcome)
  addResult({ dispatch }: StateContext<SurveyStateModel>, { index }: AddOutcome): Observable<void> {
    const outcome = {
      type: Outcomes.OUTCOME,
    } as OutcomeData;

    return this.om
      .addOutcomes(outcome, index)
      .pipe(mergeMap(([key]) => dispatch([new ChangeObject(key, ViewType.Result, false)])));
  }

  @Action(MoveOutcome)
  moveOutcome(ctx: StateContext<SurveyStateModel>, { outcome, index }: MoveOutcome): Observable<any> {
    const outcomes = [...this.store.selectSnapshot(SurveyState.outcomes())];
    const idx = outcomes.indexOf(outcome);

    if (idx !== -1) {
      moveItemInArray(outcomes, idx, index);

      this.updateSurveyItem(ctx, void 0, 'outcomes', outcomes);

      if (index > idx) {
        index++;
      }
    }

    return this.om.moveOutcome(outcome, index);
  }

  @Action(ScoreOutcome)
  scoreOutcome(ctx: StateContext<SurveyStateModel>, { surveyKey, outcomeKey, questionKey, data }: ScoreOutcome) {
    return from(this.om.scoreOutcome(surveyKey, outcomeKey, questionKey, data));
  }

  @Action(DeleteResult)
  deleteResult({ dispatch }: StateContext<SurveyStateModel>, { result }: DeleteResult): Observable<void> {
    const activeKey = this.store.selectSnapshot(RouterState.queryParams).object;
    let newKey: string = activeKey;

    if (activeKey === result.$key) {
      const outcomes = this.store
        .selectSnapshot(SurveyState.outcomes())
        .filter(({ type }) => type === Outcomes.OUTCOME);
      let index = outcomes.findIndex(({ $key }) => result.$key === $key) - 1;

      if (outcomes.length > 1 && index === -1) {
        index = 1;
      }

      newKey = index >= 0 ? outcomes[index].$key : '';

      if (!newKey) {
        const questions = this.store.selectSnapshot(SurveyState.questions());

        if (questions.length) {
          newKey = questions[questions.length - 1].$key;
        }
      }
    }

    return this.om
      .removeOutcome(result)
      .pipe(
        mergeMap(() =>
          dispatch([new ChangeObject(newKey), new NoticeRemove(BuilderType.Result, new RestoreResult(result))]),
        ),
      );
  }

  @Action(RestoreResult)
  restoreResult({ dispatch }: StateContext<SurveyStateModel>, { result }: RestoreResult): Observable<void> {
    return from(this.om.restoreOutcome(result)).pipe(
      mergeMap(() => dispatch(new ChangeObject(result.$key, ViewType.Result))),
    );
  }

  @Action(AddTrigger)
  addTrigger({ dispatch }: StateContext<SurveyStateModel>, { index }: AddTrigger): Observable<void> {
    const survey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const lang = this.store.selectSnapshot(PrefsState.language);
    const surveyDefaultLanguage = this.store.selectSnapshot(SurveyState.defaultLanguage(survey));

    const actionLink = this.em.newEmail({ survey, type: 'notification' });
    const trigger: Partial<TriggerData> = {
      type: TriggerType.Answer,
      action: TriggerAction.Email,
      actionLink,
    };

    return this.em.loadTemplate(lang, 'notification').pipe(
      take(1),
      mergeMap((template) => {
        delete template.name;
        if (surveyDefaultLanguage) {
          template.language = surveyDefaultLanguage;
        }
        return this.em.updateEmail(actionLink, template);
      }),
      mergeMap(() => this.tm.addTrigger(trigger as TriggerData, index)),
      mergeMap(([key]) => dispatch(new ChangeObject(key, ViewType.Trigger, false))),
    );
  }

  @Action(UpdateTrigger)
  updateTrigger(ctx: StateContext<SurveyStateModel>, { triggerKey, data }: UpdateTrigger) {
    return from(this.tm.updateTrigger(triggerKey, data));
  }

  @Action(DeleteTrigger)
  deleteTrigger({ dispatch }: StateContext<SurveyStateModel>, { trigger }: DeleteTrigger): Observable<void> {
    const activeKey = this.store.selectSnapshot(RouterState.queryParams).object;
    let newKey: string = activeKey;

    if (activeKey === trigger.$key) {
      const triggers = this.store.selectSnapshot(SurveyState.triggers());
      const newIndex = (triggers.indexOf(trigger) || 2) - 1;
      const newActiveTrigger = triggers[newIndex];

      if (!newActiveTrigger) {
        const results = this.store.selectSnapshot(SurveyState.outcomes());

        if (!results.length) {
          const questions = this.store.selectSnapshot(SurveyState.questions());

          if (questions.length) {
            newKey = questions[questions.length - 1].$key;
          }
        } else {
          newKey = results[results.length - 1].$key;
        }
      } else {
        newKey = newActiveTrigger.$key;
      }
    }

    return this.tm
      .removeTrigger(trigger)
      .pipe(
        mergeMap(() =>
          dispatch([new ChangeObject(newKey), new NoticeRemove(BuilderType.Trigger, new RestoreTrigger(trigger))]),
        ),
      );
  }

  @Action(RestoreTrigger)
  restoreTrigger({ dispatch }: StateContext<SurveyStateModel>, { trigger }: RestoreTrigger): Observable<void> {
    return from(this.tm.restoreTrigger(trigger)).pipe(
      mergeMap(() => dispatch(new ChangeObject(trigger.$key, ViewType.Trigger))),
    );
  }

  @Action(UpdateDesign)
  updateDesign(_, { update, surveyKey }: UpdateDesign): Observable<unknown> {
    surveyKey ??= this.store.selectSnapshot(SurveyState.activeSurveyKey);

    return from(this.sm.updateDesign(surveyKey, update));
  }

  @Action(UpdateSurveyOnline)
  updateSurveyOnline(
    { patchState, getState }: StateContext<SurveyStateModel>,
    { online }: UpdateSurveyOnline,
  ): Promise<void> | void {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    let survey = getState()[surveyKey];

    if (survey) {
      survey = { ...survey };
      survey.release = { ...survey.release };
      survey.release.online = online;

      patchState({ [surveyKey]: survey });

      return this.sm.updateRelease(surveyKey, { online });
    }
  }

  @Action(UpdateSurveyClosing)
  updateSurveyClosing(
    { patchState, getState }: StateContext<SurveyStateModel>,
    { closeAt }: UpdateSurveyClosing,
  ): Promise<void> | void {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    let survey = getState()[surveyKey];

    if (survey) {
      survey = { ...survey };
      survey.release = { ...survey.release };
      survey.release.closeAt = closeAt;

      patchState({ [surveyKey]: survey });

      return this.sm.updateRelease(surveyKey, { closeAt });
    }
  }

  @Action(QuestionScoredProcess)
  questionScoredProcess(
    { dispatch }: StateContext<SurveyStateModel>,
    { action, selected, force }: QuestionScoredProcess,
  ): Observable<any> {
    const groupKey = action.group.$key;
    const questions = this.store.selectSnapshot(SurveyState.questions());

    const triggers = this.store.selectSnapshot(SurveyState.triggers());
    const outcomes = this.store.selectSnapshot(SurveyState.outcomes());
    const group = questions.find(({ $key }) => $key === groupKey);

    const attachedQuestions = action.questions.map((question) =>
      Logic.attached(question, questions, outcomes, triggers),
    );

    const attachMoves =
      action.index != null
        ? action.questions.map((question) => Logic.attachedMove(question, questions, action.index))
        : [];

    let attached = [
      ...Logic.attached(group, questions, outcomes, triggers),
      ...(attachedQuestions.filter((att) => !!att.length)[0] || []),
      ...(attachMoves.filter((att) => !!att.length)[0] || []),
    ];

    const attachedKeys = attached.map(({ $key }) => $key);

    attached = attached.filter(({ $key }, index) => attachedKeys.lastIndexOf($key) === index);

    if (!force && attached.length) {
      let idx = attachedQuestions.findIndex((att) => att.length > 0);
      idx = idx > -1 ? idx : attachMoves.findIndex((att) => att.length > 0);
      idx = idx > -1 ? idx : 0;

      return this.store.dispatch(
        new QuestionActionDialog({
          question: action.questions[idx],
          attached,
          action: QuestionActionType.Editing,
          confirmAction: new QuestionScoredProcess(action, selected, true),
        }),
      );
    }

    const dispatchAction: any[] = action.action ? [action.action] : [];

    if (action.action instanceof UpdateGroup) {
      action.action.skipType = selected.type;

      const keys = Questions.attachedKeys(action.group, questions);

      dispatchAction.unshift(
        ...attached.map((attach) => {
          const showIf = { ...(attach.showIf || {}) };
          keys.forEach((key) => delete showIf[key]);

          if (triggers.some(({ $key }) => attach.$key === $key)) {
            return new UpdateTrigger(attach.$key, { showIf });
          } else if (outcomes.some(({ $key }) => attach.$key === $key)) {
            return new UpdateOutcome(attach.$key, { showIf });
          } else if (Questions.group(attach as QuestionData)) {
            return new UpdateGroup(attach.$key, { showIf });
          } else {
            return new UpdateQuestion(attach.$key, { showIf });
          }
        }),
      );
    }

    return from(this.qm.copyTypeSettings([{ $key: groupKey, type: Questions.GROUP_SCORED }], selected)).pipe(
      switchMap(() => (dispatchAction.length ? dispatch(dispatchAction) : of(void 0))),
    );
  }

  private getSurveyItem(
    { getState, patchState }: StateContext<SurveyStateModel>,
    surveyKey: string,
    item: keyof SurveyModel,
    transform = (a) => a,
  ): Observable<void> {
    const dataMap: Partial<
      Record<keyof SurveyModel, KeysOfType<SurveysManager, (surveyKey: string) => Observable<any>>>
    > = {
      survey: 'getSurveyData',
      design: 'getDesignData',
      locales: 'getLocalesData',
      sharing: 'getSharingData',
      release: 'getReleaseData',
      scoring: 'getScoringData',
      outcomes: 'getOutcomes',
      questions: 'getQuestions',
      triggers: 'getTriggers',
      owner: 'getOwnerData',
      rights: 'getRightsData',
    };

    const obs$: Observable<unknown> = this.sm[dataMap[item]](surveyKey);

    return (surveyKey ? concat(obs$.pipe(take(1)), obs$.pipe(debounceTime(50))) : of(null)).pipe(
      map(transform),
      map((data) => patchState({ [surveyKey]: { ...getState()[surveyKey], [item]: data } })),
      mapTo(void 0),
    );
  }

  private getSurveyHistoryItem(
    { getState, patchState }: StateContext<SurveyStateModel>,
    surveyKey: string,
    item: keyof SurveyModel,
    transform = (a) => a,
  ): Observable<void> {
    return (surveyKey ? this.sm.connectHistory(surveyKey)[item] : of(void 0)).pipe(
      map(transform),
      map((data) => {
        const survey = { ...getState()[surveyKey] };
        const history = { ...(survey.history || {}) } as SurveyHistoryModel;
        history[item] = data;
        survey.history = history;

        patchState({ [surveyKey]: survey });
      }),
    );
  }

  private getUpdateLogicActions(
    question: QuestionData,
    checkScored?: boolean,
  ): { actions: any[]; attached: BuilderData[] } {
    const questions = this.store.selectSnapshot(SurveyState.questions());
    const triggers = this.store.selectSnapshot(SurveyState.triggers());
    const outcomes = this.store.selectSnapshot(SurveyState.outcomes());
    const attached = Logic.attached(question, questions, outcomes, triggers, checkScored);
    const actions: any[] = [];

    if (attached.length > 0) {
      const keys = Questions.attachedKeys(question, questions, true);
      const logicFilter = (item) => attached.includes(item);
      const updateLogic = ({ showIf, $key }: BuilderData, update: any) => {
        showIf = { ...showIf };
        keys.forEach((key) => delete showIf[key]);
        return new update($key, { showIf });
      };

      actions.push(
        ...triggers.filter(logicFilter).map((attach) => updateLogic(attach, UpdateTrigger)),
        ...outcomes.filter(logicFilter).map((attach) => updateLogic(attach, UpdateOutcome)),
        ...questions.filter(logicFilter).map((attach) => {
          const update = Questions.group(attach) ? UpdateGroup : UpdateQuestion;
          return updateLogic(attach, update);
        }),
      );
    }

    return { actions, attached };
  }

  @Action(DeleteOutcomeSharingImage)
  deleteOutcomeSharingImage(_, { surveyKey }: DeleteOutcomeSharingImage): Observable<any> {
    return from(this.sm.updateSharing(surveyKey, { 'outcomeSharing/imageData/backgroundImage': null }));
  }

  @StreamAction(GetSurveys)
  getSurveys(ctx: StateContext<SurveyStateModel>): Observable<any> {
    return this.store.select(AccountState.isTeamAdmin).pipe(
      distinctUntilChanged(),
      switchMap((is) =>
        is ? this.sm.teamSurveyKeys() : this.store.select(AccountState.surveyRights).pipe(map(Object.keys)),
      ),
      distinctUntilChanged(isArrayShallowEqual),
      catchError(() => of([])),
      tap((keys) => {
        // remove surveys user don't have access to anymore
        const surveys = pickBy(ctx.getState(), (_, key) => keys.includes(key));
        ctx.setState(surveys);
      }),
      switchMap((surveys) =>
        !surveys.length
          ? of(void 0)
          : merge(
              ...surveys.map((surveyKey) =>
                combineLatest([
                  this.sm.getSurveyData(surveyKey, void 0, true).pipe(
                    distinctUntilChanged(isDeepEqual),
                    tap((data) => this.updateSurveyItem(ctx, surveyKey, 'survey', data)),
                  ),
                  ctx.dispatch(new GetRespondents(surveyKey)),
                ]),
              ),
            ),
      ),
      mapTo(void 0),
      shareRef(),
    );
  }

  @Action(ChangeOutcomeOptionsCount)
  changeOutcomeOptionsCount(_: StateContext<SurveyStateModel>, { count }: ChangeOutcomeOptionsCount) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const score = this.store.selectSnapshot(SurveyState.outcomeOptions()).score || null;
    const hideZeroScoreOutcomes =
      this.store.selectSnapshot(SurveyState.outcomeOptions()).hideZeroScoreOutcomes || false;

    return from(this.sm.updateResultsOptions(surveyKey, { count, score, hideZeroScoreOutcomes }));
  }

  @Action(ChangeOutcomeOptionsScore)
  changeOutcomeOptionsScore(_: StateContext<SurveyStateModel>, { score }: ChangeOutcomeOptionsScore) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const count = this.store.selectSnapshot(SurveyState.outcomeOptions()).count;
    const hideZeroScoreOutcomes =
      this.store.selectSnapshot(SurveyState.outcomeOptions()).hideZeroScoreOutcomes || false;

    return from(this.sm.updateResultsOptions(surveyKey, { count, score, hideZeroScoreOutcomes }));
  }

  @Action(ChangeOutcomeOptionsHideZeroScoreOutcomes)
  changeOutcomeOptionsHideZeroScoreOutcomes(
    _: StateContext<SurveyStateModel>,
    { hideZeroScoreOutcomes }: ChangeOutcomeOptionsHideZeroScoreOutcomes,
  ) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const count = this.store.selectSnapshot(SurveyState.outcomeOptions()).count;
    const score = this.store.selectSnapshot(SurveyState.outcomeOptions()).score || null;

    return from(this.sm.updateResultsOptions(surveyKey, { count, score, hideZeroScoreOutcomes }));
  }

  @Action(ResetOutcomeOptions)
  resetOutcomeOptions(_: StateContext<SurveyStateModel>, { count, score, hideZeroScoreOutcomes }: ResetOutcomeOptions) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);

    return from(this.sm.updateResultsOptions(surveyKey, { count, score, hideZeroScoreOutcomes }));
  }

  @Action(AddQuestionChoice)
  addQuestionChoice(ctx: StateContext<SurveyStateModel>, { question, content }: AddQuestionChoice): Observable<any> {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const questionData = questions.find(({ $key }) => $key === question);
    const choiceList = questionData.choiceList || [];
    const idx = choiceList.length || 0;
    const newChoice = new ChoiceItemData();
    const order =
      choiceList.filter((choice) => choice.$key !== 'other').reduce((a, b) => (b.order > a ? b.order : a), 0) || 0;
    newChoice.$insertKey = this.qm.getNewKey();
    newChoice.$key = newChoice.$insertKey;
    newChoice.order = order;

    let locales$: Observable<unknown> = of(void 0);

    if (content) {
      newChoice.content = content.text;
      locales$ = this.updateChoiceContentTranslation(question, newChoice.$key, content);
    }

    this.updateQuestionData(ctx, question, { [`choiceList/${idx}`]: newChoice });

    return forkJoin([this.qm.addChoices(question, [newChoice]), locales$]);
  }

  @Action(AddQuestionChoices)
  addQuestionChoices(
    _: StateContext<SurveyStateModel>,
    { question, choices, language }: AddQuestionChoices,
  ): Observable<any> {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const questionData = questions.find(({ $key }) => $key === question);
    const choiceList = questionData.choiceList || [];
    const order = Math.max(...choiceList.filter((choice) => choice.$key !== 'other').map(({ order }) => order), 0);

    const newChoices = choices.map((choice) => {
      const newChoice = new ChoiceItemData();
      newChoice.$insertKey = this.qm.getNewKey();
      newChoice.$key = newChoice.$insertKey;
      newChoice.order = order;
      newChoice.content = choice;

      return newChoice;
    });

    const locales$ = this.updateChoicesContentsTranslations(
      question,
      newChoices
        .filter((choice) => choice.content)
        .map((choice) => ({
          $key: choice.$key,
          text: choice.content,
        })),
      language,
    );

    return forkJoin([this.qm.addChoices(question, newChoices), locales$]);
  }

  private updateChoiceContentTranslation(
    question: string,
    choice: string,
    content: { text: string; language: string },
  ): Observable<unknown> {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const entries = [];
    addLocaleEntry(entries, content.text, entryKey(QuestionTranslationMap.choiceList[0].content, question, choice));
    const localeUpdates = translatorEntriesToUpdate(entries, true);

    return this.lm.updateSurveyTranslation(surveyKey, content.language, localeUpdates);
  }

  private updateChoicesContentsTranslations(
    question: string,
    choices: { $key: string; text: string }[],
    language: string,
  ): Observable<unknown> {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const entries = [];

    choices.forEach(({ text, $key }) => {
      addLocaleEntry(entries, text, entryKey(QuestionTranslationMap.choiceList[0].content, question, $key));
    });

    const localeUpdates = translatorEntriesToUpdate(entries, true);

    return this.lm.updateSurveyTranslation(surveyKey, language, localeUpdates);
  }

  private updateQuestionData({ setState }: StateContext<SurveyStateModel>, key: string, update: any): void {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);
    const idx = questions.findIndex(({ $key }) => $key === key);
    const question = questions[idx];

    if (!question) {
      return;
    }

    let updatedQuestion = { ...question };

    Object.entries(update).forEach(([path, value]) => {
      updatedQuestion = setByPath(updatedQuestion, path, value);
    });

    setState(patch({ [surveyKey]: patch({ questions: updateItem(idx, updatedQuestion) }) }));
  }

  @Action(UpdateQuestionChoiceContent)
  updateQuestionChoiceContent(
    ctx: StateContext<SurveyStateModel>,
    { question, choice, content }: UpdateQuestionChoiceContent,
  ): Observable<any> {
    const idx = this.getIndexFromChoice(question, choice);

    if (idx === -1) {
      return of(void 0);
    }

    let locale$: Observable<unknown> = of(void 0);

    if (content && typeof content === 'object') {
      locale$ = this.updateChoiceContentTranslation(question, choice, content);
      content = content.text;
    }

    this.updateQuestionData(ctx, question, { [`choiceList/${idx}/content`]: content });

    return forkJoin([
      this.qm.updateQuestion(`${question}/choiceList/${choice}`, { content: content as string }),
      locale$,
    ]);
  }

  @Action(UpdateQuestionChoiceData)
  updateQuestionChoiceData(
    ctx: StateContext<SurveyStateModel>,
    { question, choice, data }: UpdateQuestionChoiceData,
  ): Observable<any> {
    const idx = this.getIndexFromChoice(question, choice);

    if (idx === -1) {
      return of(void 0);
    }

    const patches = Object.entries(data).reduce(
      (updates, [path, update]) => ({
        ...updates,
        [`choiceList/${idx}/${path}`]: update,
      }),
      {},
    );

    this.updateQuestionData(ctx, question, patches);

    return from(this.qm.updateQuestion(`${question}/choiceList/${choice}`, data));
  }

  @Action(DeleteQuestionChoice)
  deleteQuestionChoice(
    ctx: StateContext<SurveyStateModel>,
    { question, choice }: DeleteQuestionChoice,
  ): Observable<any> {
    const idx = this.getIndexFromChoice(question, choice);

    if (idx === -1) {
      return of(void 0);
    }

    this.updateQuestionData(ctx, question, { [`choiceList/${idx}`]: void 0 });

    return this.qm.removeChoice(question, { $key: choice });
  }

  @Action(ToggleQuestionOther)
  toggleQuestionOther(_, { question, enabled }: ToggleQuestionOther): Observable<any> {
    return from(this.qm.toggleOtherChoice(question, enabled));
  }

  @Action(MoveQuestionChoice)
  moveQuestionChoice(_, { question, choice, index }: MoveQuestionChoice): Observable<any> {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const questionData = questions.find(({ $key }) => $key === question);
    const choiceData = (questionData?.choiceList || []).find(({ $key }) => $key === choice);

    return this.qm.moveChoice(question, choiceData, index);
  }

  @Action(SwitchQuestionChoice)
  switchQuestionChoice(
    { setState }: StateContext<SurveyStateModel>,
    { question, sourceChoice, targetChoice }: SwitchQuestionChoice,
  ): Observable<any> {
    const sourceIdx = this.getIndexFromChoice(question, sourceChoice);
    const targetIdx = this.getIndexFromChoice(question, targetChoice);

    const source = this.getChoiceFromQuestion(question, sourceChoice);
    const target = this.getChoiceFromQuestion(question, targetChoice);

    if (!source || !target) {
      return of(void 0);
    }

    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurveyKey);

    setState(
      patch({
        [surveyKey]: patch({
          questions: updateItem(
            ({ $key }) => question === $key,
            patch({
              choiceList: updateItem(sourceIdx, (item) => ({
                ...item,
                'order': target.order,
                '.priority': target.order,
              })),
            }),
          ),
        }),
      }),
    );

    setState(
      patch({
        [surveyKey]: patch({
          questions: updateItem(
            ({ $key }) => question === $key,
            patch({
              choiceList: updateItem(targetIdx, (item) => ({
                ...item,
                'order': source.order,
                '.priority': source.order,
              })),
            }),
          ),
        }),
      }),
    );

    return this.qm.switchChoice(question, sourceChoice, targetChoice);
  }

  private updateSurveyItem(
    { getState, patchState }: StateContext<SurveyStateModel>,
    surveyKey: string | undefined,
    item: keyof SurveyModel,
    data: any,
  ) {
    surveyKey ??= this.store.selectSnapshot(RouterState.routeParams).survey;
    const state = getState();
    const model = state[surveyKey];

    patchState({ ...state, [surveyKey]: { ...model, [item]: data } });
  }

  private getIndexFromChoice(question: string, choice: string): number {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const questionData = questions.find(({ $key }) => $key === question);
    return (questionData?.choiceList || []).findIndex(({ $key }) => $key === choice);
  }

  private getChoiceFromQuestion(question: string, choice: string): ChoiceItemData | undefined {
    const questions = [...this.store.selectSnapshot(SurveyState.questions())];
    const questionData = questions.find(({ $key }) => $key === question);
    return (questionData?.choiceList || []).find(({ $key }) => $key === choice);
  }

  @Action(PublishSurvey)
  publishSurvey({ dispatch }: StateContext<SurveyStateModel>, { surveyKey }: PublishSurvey): Observable<void> {
    const parts = [this.store.selectSnapshot(AccountState.teamKey), surveyKey];

    return this.cf
      .post(Commands.DataPublish, parts.join('/'))
      .pipe(tap(() => dispatch(new TriggerFeedbackSurvey(FeedbackSurvey.GeneralZeffiSurvey))));
  }

  @Action(DiscardDraft)
  discardDraft(_: StateContext<SurveyStateModel>, { surveyKey }: DiscardDraft): Observable<void> {
    return this.cf.delete(Commands.DeleteDraft, `${this.store.selectSnapshot(AccountState.teamKey)}/${surveyKey}`);
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ setState }: StateContext<SurveyStateModel>) {
    setState({});
  }

  @Action(ArchiveQuestion)
  archiveQuestion(ctx: StateContext<SurveyStateModel>, { data, restore }: ArchiveQuestion): Observable<any> {
    if (restore) {
      return from(this.qm.unarchiveQuestion(data.$key));
    } else {
      const warningDialogDisabled = this.store.selectSnapshot(PrefsState.hideArchiveWarningDialog);
      const hasLogicDependencies = this.store.selectSnapshot(SurveyState.hasLogicDependencies(data));

      if (hasLogicDependencies && !warningDialogDisabled) {
        return this.store.dispatch(new ShowArchiveWarning(data));
      } else {
        return from(this.qm.archiveQuestion(data.$key));
      }
    }
  }

  @Action(RemoveArchivedLogic)
  RemoveArchivedLogic(
    { dispatch }: StateContext<SurveyStateModel>,
    { data, type }: RemoveArchivedLogic,
  ): Observable<any> {
    const UpdateAction =
      type === BuilderType.Question
        ? UpdateQuestion
        : type === BuilderType.Group
          ? UpdateGroup
          : type === BuilderType.Result
            ? UpdateOutcome
            : type === BuilderType.Trigger
              ? UpdateTrigger
              : null;

    if (!data?.showIf || !UpdateAction) {
      return of(null);
    }

    const questions = this.store.selectSnapshot(SurveyState.questions());
    const archivedQuestionKeys = questions.filter((q) => q.archived).map((q) => q.$key);
    const showIf = excludeByKeyList(data.showIf, archivedQuestionKeys);

    return dispatch(new UpdateAction(data.$key, { showIf }));
  }

  @Action(ArchiveOutcome)
  archiveOutcome(ctx: StateContext<SurveyStateModel>, { data, restore }: ArchiveOutcome): Observable<any> {
    return restore ? from(this.om.unarchiveOutcome(data.$key)) : from(this.om.archiveOutcome(data.$key));
  }

  @Action(ArchiveTrigger)
  archiveTrigger(ctx: StateContext<SurveyStateModel>, { data, restore }: ArchiveTrigger): Observable<any> {
    return restore ? from(this.tm.unarchiveTrigger(data.$key)) : from(this.tm.archiveTrigger(data.$key));
  }

  @Action(MigrateDefaultLocales)
  migrateDefaultLocales(
    ctx: StateContext<SurveyStateModel>,
    { surveyKey }: MigrateDefaultLocales,
  ): Observable<unknown> {
    const surveyData = this.store.selectSnapshot(SurveyState.survey(surveyKey));

    if (surveyData.localesMigrated || !surveyData.language) {
      return EMPTY;
    }

    const sharing = this.store.selectSnapshot(SurveyState.sharing(surveyKey));
    const locales = this.store.selectSnapshot(SurveyState.locales(surveyKey));
    const outcomes = this.store.selectSnapshot(SurveyState.outcomes(surveyKey));
    const questions = this.store.selectSnapshot(SurveyState.questions(surveyKey));

    const language =
      this.store.selectSnapshot(LocalesState.languages).find((lang) => lang.code === surveyData.language) ||
      ({ code: surveyData.language, name: LanguageOtherName } as LanguageData2);

    const existingTranslations = locales?.strings[language.code] || {};

    return this.sm.migrateSurveyLanguage(
      surveyKey,
      language,
      existingTranslations,
      surveyData,
      questions,
      outcomes,
      sharing,
    );
  }
}
